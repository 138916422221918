<template>
    <div class="key-card" v-if="!isCreator && display == 'card'">
        <div class="key-options">
            <button
                v-if="checkKeyRight"
                class="options-button dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <i class="fas fa-ellipsis-v"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="options-button">
                <a @click="consultDetails(keyObject)" class="dropdown-item">
                    <i class="fas fa-pencil-alt"></i>
                    {{ $t("button.modify") }}
                </a>
                <a
                    v-if="checkDeleteRight"
                    @click="deleteKey()"
                    class="dropdown-item"
                >
                    <i class="fas fa-trash-alt"></i>
                    {{ $t("button.delete") }}
                </a>
            </ul>
        </div>
        <div class="key-informations" @click="consultDetails(keyObject)">
            <img
                v-if="keyObject.image !== undefined"
                :src="imageBaseURL + keyObject.image"
            />
            <i v-else class="fas fa-square"></i>
            <i
                v-if="keyObject.copie !== '0'"
                class="fas fa-exclamation-triangle copie"
            >
                <span>
                    {{ $t("keyCard.copie") }}
                </span>
            </i>
            <h1 v-if="keyObject.type === '-1'">{{ $t("keyCard.mobil") }}</h1>
            <h1 v-else-if="keyObject.hf === '1'">{{ $t("keyCard.teleco") }}</h1>
            <h1 v-else-if="keyObject.type > '400' && keyObject.type < '500'">
                {{ $t("keyCard.kibolt.name") }}
            </h1>
            <h1 v-else>{{ $t("keyCard.badge") }}</h1>
            <h2>
                {{ $t("keyCard.serial", { serial: keyObject.serialgrave }) }}
            </h2>
            <div
                class="key-color"
                :class="
                    'tag-color-' +
                    (keyObject.tagcolor ? keyObject.tagcolor : 'other')
                "
            >
                {{
                    keyObject.tagcolor
                        ? $t("keyCard.colors." + keyObject.tagcolor)
                        : $t("keyCard.colors.other")
                }}
            </div>
            <div
                class="load"
                v-if="
                    keyObject.type > '400' &&
                    keyObject.type < '500' &&
                    keyObject.etat === '1'
                "
            >
                {{ $t("keyCard.kibolt.update") }}
            </div>
            <div v-if="keyObject.type > '400' && keyObject.type < '500' && keyObject.etat === '2'" class="load">
                {{ $t("keyCard.kibolt.lost") }}
            </div>
            <div class="comment">
                <p>{{ keyObject.comments }}</p>
            </div>
        </div>
    </div>
    <div class="key-list" v-else-if="!isCreator && display == 'list'">
        <div class="key-list-content">
            <div class="visual" @click="consultDetails(keyObject)">
                <img
                    v-if="keyObject.image !== undefined"
                    :src="imageBaseURL + keyObject.image"
                />
            </div>
            <div class="type" @click="consultDetails(keyObject)">
                <h1 v-if="keyObject.type === '-1'">
                    {{ $t("keyCard.mobil") }}
                </h1>
                <h1 v-else-if="keyObject.hf === '1'">
                    {{ $t("keyCard.teleco") }}
                </h1>
                <h1
                    v-else-if="keyObject.type > '401' && keyObject.type < '500'"
                >
                    {{ $t("keyCard.kibolt.name") }}
                </h1>
                <h1 v-else>{{ $t("keyCard.badge") }}</h1>
            </div>
            <div class="serial" @click="consultDetails(keyObject)">
                <h1>
                    {{
                        $t("keyCard.serial", { serial: keyObject.serialgrave })
                    }}
                </h1>
            </div>
            <div class="color" @click="consultDetails(keyObject)">
                <div
                    :class="
                        'tag-color-' +
                        (keyObject.tagcolor ? keyObject.tagcolor : 'other')
                    "
                    class="tag-color"
                >
                    <h1 v-if="keyObject.tagcolor === 'other'">
                        {{ $t("keyCard.colors.other") }}
                    </h1>
                </div>
            </div>
            <div class="key-comments">
                <p>{{ keyObject.comments }}</p>
            </div>
            <div class="key-options">
                <button
                    v-if="checkKeyRight"
                    class="options-button dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="options-button">
                    <a @click="consultDetails(keyObject)" class="dropdown-item">
                        <i class="fas fa-pencil-alt"></i>
                        {{ $t("button.modify") }}
                    </a>
                    <a
                        v-if="checkDeleteRight"
                        @click="deleteKey()"
                        class="dropdown-item"
                    >
                        <i class="fas fa-trash-alt"></i>
                        {{ $t("button.delete") }}
                    </a>
                </ul>
            </div>
        </div>
    </div>
    <div
        :class="'key-' + display + '-creator'"
        v-else-if="checkRight('G_KEY')"
        @click="createAction()"
    >
        <i class="fas fa-plus"></i>
        <h1>{{ $t("keyCard.create") }}</h1>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"

import { deleteFob } from "@/services/intratone/fob.js"
import { mapState } from "vuex"

export default {
    name: "StairwellCard",
    mixins: [v1mixin],
    components: {},
    props: {
        keyObject: {
            type: Object,
            default: function () {
                return null
            },
        },
        isCreator: {
            type: Boolean,
            default: false,
        },
        hideResidentCreation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
        }
    },
    computed: {
        checkKeyRight() {
            if (this.checkRight("G_KEY_MOVE")) {
                return true
            } else if (
                this.keyObject.type > "400" &&
                this.keyObject.type < "500"
            ) {
                return this.checkRight("G_KIBOLT")
            } else if (this.keyObject.type === "-1") {
                return this.checkRight("G_KEY_MOBIL")
            }
            return this.checkRight("G_KEY")
        },
        checkDeleteRight() {
            if (this.keyObject.type > "400" && this.keyObject.type < "500") {
                return false
            } else if (this.keyObject.type === "-1") {
                return this.checkRight("G_KEY_MOBIL")
            }
            return this.checkRight("G_KEY")
        },
        ...mapState({
            display: (state) => state.account.displayKeyCard,
        }),
    },
    methods: {
        createAction() {
            if (this.hideResidentCreation === true) {
                this.openV1Modal(
                    "data/appart/appart_add/hide_bar=1",
                    "Act_Zone"
                )
            } else {
                this.openV1Modal("data/appart/appart_add", "Act_Zone")
            }
        },
        async deleteKey() {
            try {
                if (
                    window.confirm(
                        this.$t("keyCard.delete", {
                            serial: this.keyObject.serialgrave,
                        })
                    )
                ) {
                    let fobDelResponse = await deleteFob(this.keyObject.id)
                    if (fobDelResponse === null) {
                        throw new Error()
                    }
                    if (fobDelResponse.state === "error") {
                        if (fobDelResponse.code === "API_RESTRICTED") {
                            this.$router.push({
                                path: "/error/401",
                            })
                        } else {
                            this.$router.push({
                                path: "/error/404",
                            })
                        }
                        return
                    } else {
                        this.$emit("fob-deleted")
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        consultDetails(keyObject) {
            if (keyObject.type > "400" && keyObject.type < "500") {
                this.goToV1View("data/kibolt/key/cle_kibolt/id=" + keyObject.id)
            } else {
                this.goToV1View("data/clef/mode=0&rech=0&id=" + keyObject.id)
            }
        },
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.copie {
    color: $status-red;
    margin-left: 20px;
    font-size: $big;

    &:hover > span {
        display: flex;
    }

    span {
        display: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 5px;
        position: absolute;
        z-index: 1;
        padding: 10px;
        width: auto;
        margin-left: 20px;
        background-color: $white;
        border: solid 1px $blue-light;
        border-radius: 10px;
        font-size: $small;
        color: $blue-light;
        font-weight: normal;
        font-family: "Avenir Heavy";
    }
}

.key-card {
    width: 200px;
    height: 200px;
    border-radius: 30px;
    box-shadow: 0px 0 15px 0 rgb(0 0 0 / 15%);
    transition: box-shadow 200ms;
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .key-options {
        padding: 14px 14px 0 14px;
        display: flex;
        align-items: center;
        justify-content: right;

        button {
            background-color: $white;
            border-radius: 30px;
            border: none;
            padding: 6px 6px 5px 6px;
            transition: background-color 200ms;

            i {
                font-size: $big;
                color: $blue-neutral;
            }
        }

        button:hover {
            background-color: $grey-neutral;
        }

        .dropdown-item {
            font-size: $small;
            transition: background-color 200ms, color 200ms, padding 200ms;
            color: $blue-neutral;
            font-family: "Avenir Heavy";

            i {
                font-size: $normal;
                margin-right: 15px;
            }
        }

        .dropdown-item:hover {
            cursor: pointer;
            color: $orange-neutral;
            background-color: $grey-lighter;
            padding-left: 30px;
        }

        .dropdown-toggle:after {
            display: none;
        }
    }

    .key-informations {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
        padding: 0;
        text-align: center;
        border-radius: 0 0 15px 15px;

        img {
            max-width: 2.5em;
            max-height: 2.5em;
        }

        h1 {
            font-family: "Avenir Heavy";
            font-size: $big;
            margin: 0;
            margin: 0;
        }

        h2 {
            font-size: $normal;
            margin: 0;
        }

        .load {
            color: red;
            font-size: $small;
            margin: 5px 0 0 0;
        }

        .key-color {
            border-radius: 30px;
            width: 100px;
            margin: 0 auto 0 auto;
        }

        .comment {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding-left: 2em;
            padding-right: 2em;
            font-size: $small;
            font-style: italic;
            line-height: 1em;

            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                font-size: $small;
                word-break: break-all;
            }
        }

        .tag-color-black {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-black;
            color: $white;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-beige {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-beige;
            width: 8em;
            padding: 0.2em 0;
            color: $black;
        }

        .tag-color-green {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-green;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-blue {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-blue;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-red {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-red;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-brown {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-brown;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-grey {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-grey;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-yellow {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-yellow;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-orange {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-orange;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-purple {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tag-purple;
            width: 8em;
            padding: 0.2em 0;
            color: $white;
        }

        .tag-color-white {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $blue-light;
            width: 8em;
            padding: 0.2em 0;
            color: $blue-light;
        }

        .tag-color-other {
            color: $blue-light;
            padding: 0.4em 0;
            width: 100%;

            h1 {
                font-size: $normal;
                margin: 0;
            }
        }
    }
}

.key-card:hover {
    cursor: pointer;
    box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 25%);
}

.key-card-creator {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    border-radius: 15px;
    gap: 0.5em;
    background-color: $orange-neutral;
    color: $white;
    border: none;
    padding: 1em 3em;
    transition: background-color 200ms;
    cursor: pointer;

    i {
        color: $white;
        font-size: $big;
        margin: 0 !important;
    }

    h1 {
        font-size: $normal;
        font-family: $font_avenir_heavy;
        color: $white;
        margin: 0 !important;
    }
}

.key-list-creator {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    color: $orange-neutral;
    padding: 1em 3em;
    border-bottom: 1px solid $grey-light;
    cursor: pointer;

    &:hover {
        background-color: $orange-residence-dark;
        transition: background-color 200ms;
    }

    i {
        color: $orange-neutral;
        font-size: $big;
    }

    h1 {
        margin: 0;
        font-size: $normal;
        font-family: $font_avenir_heavy;
        color: $orange-neutral;
    }
}

.key-list {
    width: 100%;

    .key-list-title {
        display: grid !important;
        grid-template-columns: 1fr 3fr 3fr 3fr 3fr 1fr;
        grid-gap: 1em;
        font-size: $normal;
        border-bottom: 1px solid $grey-light;
        padding: 0.5em 0;

        div {
            font-family: $font_avenir_heavy;
            color: $grey-darker;
        }

        div:nth-child(5) {
            display: flex;
            justify-content: end;
        }
    }

    .key-list-content {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 1fr 3fr 1fr;
        align-items: center;
        transition: all 200ms;
        cursor: pointer;
        border-bottom: 1px solid $grey-light;

        &:hover {
            background-color: $grey-light;
            transition: all 200ms;

            .key-options {
                button {
                    background-color: $grey-light;
                }
            }
        }

        .visual {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 1em 0;

            img {
                width: 2.7em;
            }
        }

        .type {
            display: flex;
            align-items: center;
            padding: 1em 0;

            h1 {
                font-size: $normal;
                margin: 0;
            }
        }

        .serial {
            display: flex;
            align-items: center;
            padding: 1em 0;

            h1 {
                font-size: $normal;
                margin: 0;
            }
        }

        .color {
            display: flex;
            align-items: center;
            width: 100%;

            .tag-color-black {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-black;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-beige {
                width: 30px;
                height: 30px;
                padding: 1.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-beige;
                color: $black;
                border-radius: 9px;
            }

            .tag-color-green {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-green;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-blue {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-blue;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-red {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-red;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-brown {
                width: 30px;
                height: 30px;

                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-brown;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-grey {
                width: 30px;
                height: 30px;

                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-grey;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-yellow {
                width: 30px;
                height: 30px;

                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-yellow;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-orange {
                width: 30px;
                height: 30px;

                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-orange;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-purple {
                width: 30px;
                height: 30px;

                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $tag-purple;
                color: $white;
                border-radius: 9px;
            }

            .tag-color-white {
                width: 30px;
                height: 30px;

                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $blue-light;
                color: $blue-light;
                border-radius: 9px;
            }

            .tag-color-other {
                color: $blue-light;
                padding: 0.4em 0;
                width: 100%;

                h1 {
                    font-size: $normal;
                    margin: 0;
                }
            }
        }

        .key-comments {
            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                font-size: $small;
                word-break: break-all;
            }
        }

        .key-options {
            height: 25%;
            padding-right: 14px;
            padding-left: 14px;
            display: flex;
            align-items: center;
            justify-content: end;

            button {
                background-color: $white;
                border-radius: 30px;
                border: none;
                padding: 6px 6px 5px 6px;
                transition: background-color 200ms;

                i {
                    font-size: $big;
                    color: $blue-neutral;
                }
            }

            button:hover {
                background-color: $grey-neutral;
            }

            .dropdown-item {
                font-size: $small;
                transition: background-color 200ms, color 200ms, padding 200ms;
                color: $blue-neutral;
                font-family: "Avenir Heavy";

                i {
                    font-size: $normal;
                    margin-right: 15px;
                }
            }

            .dropdown-item:hover {
                cursor: pointer;
                color: $orange-neutral;
                background-color: $grey-lighter;
                padding-left: 30px;
            }

            .dropdown-toggle:after {
                display: none;
            }
        }
    }
}

.key-card-creator:hover {
    background-color: $orange-light;
    color: $white;
}
</style>
